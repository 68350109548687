import React, { useState, useEffect } from "react";
import Input from "../../../../../../Shared/Input/Index";
import { Form, FormGroup, Row, Col } from "reactstrap";
import axios from "axios";
import CommonModal from "../../common/modal";
import { clientService } from "Services";
const FormModal = ({ modal, NewMessage, toggle, defaultVal }) => {
  const [token, setToken] = useState("");
  useEffect(() => {
    axios
      .get(`https://trial.mobiscroll.com/content/countries.json`)
      .then((resp) => {
        const countries: any = [];
        for (let i = 0; i < resp.data.length; ++i) {
          const country = resp.data[i];
          countries.push({ text: country.text, value: country.value });
        }
        setCountries(countries);
      });

    const tokenGetting = localStorage.getItem("token");
    setToken(tokenGetting);
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [countries, setCountries] = useState([]);
  const [, setRec] = useState({});
  const modalSubmit = (e) => {
    e.preventDefault();
    // handle form submission
    handleSubmit();
  };

  const validation = () => {
    if (
      firstName === "" &&
      lastName === "" &&
      email === "" &&
      phoneNumber === "" &&
      country === "" &&
      city === ""
    ) {
      alert("Please fill all fields");
    } else {
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phoneNumber,
        password: city,
        country: country,
        city: city,
      };
      clientService
        .postClient(data, token)
        .then((res) => {
          setRec({
            ...res,
            loading: false,
          });
          alert("client added successfully");
        })
        .catch((err) => {
          console.log("err ", err);
          alert("Error adding client");
        });
    }
  };

  const handleSubmit = () => {
    validation();
  };
  return (
    <CommonModal
      isOpen={modal}
      title={NewMessage}
      toggler={toggle}
      formSubmit={modalSubmit}
      isButtonShown={false}
    >
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="6">
            <FormGroup>
              <Input
                label="First Name:"
                type="text"
                placeholder="Enter your first name"
                value={firstName}
                onChange={setFirstName}
                required
                elementType="input"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Input
                label="Last Name:"
                type="text"
                placeholder="Enter your last name"
                value={lastName}
                onChange={setLastName}
                required
                minLength={3}
                maxLength={20}
                elementType="input"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <Input
                label="Phone Number:"
                type="text"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                minLength={3}
                maxLength={20}
                elementType="input"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Input
                label="Email Address:"
                type="text"
                placeholder="Enter your email"
                value={email}
                pattern={/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/}
                onChange={setEmail}
                required
                minLength={3}
                elementType="input"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <Input
                label="Country:"
                placeholder="Enter your Country"
                value={country}
                onChange={setCountry}
                elementType="select"
                option={countries}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Input
                label="City:"
                type="text"
                elementType="input"
                placeholder="Enter your city"
                value={city}
                onChange={setCity}
                required
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </CommonModal>
  );
};

export default FormModal;
