import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row, Media, CardHeader, Table } from 'reactstrap';
import { H4, Currency } from '../../../../../AbstractElements';
import { dashboardService } from "Services";
import { FInput, FSelect } from 'CommonElements/Form';
import Select from "react-select";
import moment from 'moment';

const getDates = () => {
  const newDate = moment();
  const dateToday = newDate.format('yyyy-MM-DD');
  const dateTomorrow = newDate.add(1, 'days').format('yyyy-MM-DD');

  const dateStartOfMonth = newDate.startOf('month').format('yyyy-MM-DD');
  const dateStartOfLastMonth = newDate.subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
  const dateEndOfLastMonth = newDate.subtract(1, 'months').endOf('month').format('yyyy-MM-DD');
  const dateStartofWeek = newDate.startOf('week').format('yyyy-MM-DD');
  const dateStartofYear = newDate.startOf('year').format('yyyy-MM-DD');
  

  return {
    dateToday,
    dateStartOfMonth,
    dateStartOfLastMonth,
    dateEndOfLastMonth,
    dateTomorrow,
    dateStartofWeek,
    dateStartofYear,
  };
};

const SmallApexChart = ({ibAccounts = {}, withdrawals={}, depositsSales = []}) => {
  const [depositsByDate, setDepositsByDate] = useState({});

  const loadData = (dateFrom = datesObj.dateToday, dateTo = datesObj.dateTomorrow) => {
    dashboardService.getDepositsByDate(dateFrom, dateTo).then(res => {
      setDepositsByDate(res);
    }).catch(err => {
      console.log('err ', err);
    });
  }
  const datesObj = getDates();
  useEffect(()=> {
    loadData();
  }, [])
  return (
    <>
    <Col xl='4'>
      <Col xl='12 xl-33' className={`chart_data_right `}>
          <Card>
            <CardHeader className='p-3'>
              Ib Accounts
            </CardHeader>
            <CardBody>
              {ibAccounts && 
                <Media className='align-items-center'>
                  <Media body className='right-chart-content'>
                    <H4>
                      <div>
                        {ibAccounts.lastMonth || 0}
                      </div>
                      <span className='new-box'>{'Last Month'}</span>
                    </H4>
                    <span>
                      {'Month to Date'} ({ibAccounts.currentMonth || 0})
                    </span>
                  </Media>
                  <div>
                    <Media body className='right-chart-content'>
                      <H4>
                        <div>
                          {ibAccounts.today || 0}
                        </div>
                        <span className='new-box'>{'Today'}</span>
                      </H4>
                    </Media>
                  </div>
                </Media>
              }
            </CardBody>
          </Card>
        </Col>
        <Col xl='12 xl-33' className={`chart_data_right `}>
          <Card>
            <CardHeader className='p-3'>
              Withdrawals
            </CardHeader>
            <CardBody>
              {withdrawals && 
                <Media className='align-items-center'>
                  <Media body className='right-chart-content'>
                    <H4>
                      <div>
                        {withdrawals.lastMonth || 0} $
                      </div>
                      <span className='new-box'>{'Last Month'}</span>
                    </H4>
                    <span>
                      {'Month to Date'} ({withdrawals.currentMonth || 0} $)
                    </span>
                  </Media>
                  <div>
                    <Media body className='right-chart-content'>
                      <H4>
                        <div>
                          {withdrawals.today || 0} $
                        </div>
                        <span className='new-box'>{'Today'}</span>
                      </H4>
                    </Media>
                  </div>
                </Media>
              }
            </CardBody>
          </Card>
        </Col>
        <Col xl='12 xl-33' className={`chart_data_right `}>
          <Card>
            <CardHeader className='p-3'>
              Deposits
            </CardHeader>
            <CardBody className='p-3'>
              <Row>

              <Col xl='6'>
              <Select
                classNamePrefix="addl-class"
                defaultValue={{label: 'Today',       value:`${datesObj.dateToday}:${datesObj.dateTomorrow}`}}
                options={[
                  {label: 'Today',       value:`${datesObj.dateToday}:${datesObj.dateTomorrow}`},
                  {label: 'This Week',   value:`${datesObj.dateStartofWeek}:${datesObj.dateTomorrow}`},
                  {label: 'This Month',  value:`${datesObj.dateStartOfMonth}:${datesObj.dateTomorrow}`},
                  {label: 'Last Month',  value:`${datesObj.dateStartOfLastMonth}:${datesObj.dateStartOfMonth}`},
                  {label: 'This Year',   value:`${datesObj.dateStartofYear}:${datesObj.dateTomorrow}`},
                ]}
                // value={newOptions.find((c) => c.value === value)}
                isMulti={false}
                placeholder={'Select Time Span'}
                onChange={(e) => {
                  const [dateFrom, dateTo] = e.value.split(':');
                  loadData(dateFrom, dateTo);
                }}
              />
              </Col>
              <Col xl='6'>
              {depositsByDate && 
                <Media className='align-items-center'>
                  <Media body className='right-chart-content'>
                    <H4>
                      <div>
                        {depositsByDate.total || 0} $
                      </div>
                    </H4>
                  </Media>
                </Media>
              }
              </Col>
              </Row>
              
            </CardBody>
          </Card>
        </Col>
    </Col>
    <Col xl='8'>
    <Col xl='12 xl-33' className={`chart_data_right `}>
          <Card>
            <CardHeader className='p-3'>
              Deposit By Sales
            </CardHeader>
            <CardBody>
            <div className="table-responsive">
                <Table hover>
                  <thead>
                    <tr style={{verticalAlign: 'top'}}>
                      <th scope="col">{"Name"}</th>
                      <th scope="col">{"Current Month FTD"}</th>
                      <th scope="col">{"Last Month FTD"}</th>
                      <th scope="col">{"Current Month Deposits"}</th>
                      <th scope="col">{"Last Month Deposits"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositsSales &&
                      depositsSales.map((item, id) => (
                        <tr key={id}>
                          <td>{item['Customer.Agent.firstName']} {' '} {item['Customer.Agent.lastName']}</td>
                          <td>0 $</td>
                          <td>0 $</td>
                          <td>{item.currentMonth || 0} $</td>
                          <td>{item.lastMonth || 0} $</td>

                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
    </Col>

    </>
  );
};

export default SmallApexChart;
