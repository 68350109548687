import React, { Fragment } from 'react';
import { H6 } from '../../../AbstractElements';
import { Col, Card, Row } from 'reactstrap';
import {  Label, Input } from 'reactstrap'

const ClientDetailHeader = ({customerId, data}) => {
  const arr = [
    {key: 'Is Funded', value: data.isFunded},
    // {key: 'Email Verified', value: data.emailVerified},
    // {key: 'Phone Verified', value: data.phoneVerified},
    {key: 'Is Active', value: data.isActive},
    {key: 'Is Blocked', value: data.isBlocked},
    {key: 'Is Client', value: data.isClient},
    {key: 'Is Ib', value: data.isIb},
    {key: 'Is Lead', value: data.isLead},

  ];
  return (
    <Fragment>
        <Card className='hovercard text-center'>
          <div className='info'>
            <Row className='step3' data-intro='This is the your details'>
              <Col sm='6' lg='4' className='order-sm-1 order-xl-0'>
                <Row>
                  <Col md='6'>
                    <div className='ttl-info text-start'>
                      <H6>
                        <i className='fa fa-envelope me-2'></i> {'Email'}
                      </H6>
                      <span>{data.email}</span>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-sm-mb-0'>
                      <H6>
                        <i className='fa fa-calendar me-2'></i>
                        {'DOB'}
                      </H6>
                      <span>{data.dob}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm='12' lg='4' className='order-sm-0 order-xl-1'>
                <div className='user-designation'>
                  <div className='title'>
                    <a  href='/'>
                      {data.firstName} {' '} {data.lastName}
                    </a>
                  </div>
                  <div className='desc mt-2'>{data.profession}</div>

                </div>
              </Col>
              <Col sm='6' lg='4' className='order-sm-2 order-xl-2'>
                <Row>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-xs-mt'>
                      <H6>
                        <i className='fa fa-phone me-2'></i>
                        {'Phone'}
                      </H6>
                      <span>{data.phone}</span>
                    </div>
                  </Col>
                  <Col md='6'>
                    <div className='ttl-info text-start ttl-sm-mb-0'>
                      <H6>
                        <i className='fa fa-location-arrow me-2'></i>
                        {'Location'}
                      </H6>
                      <span>{data.country}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr style={{marginTop: '2rem', marginBottom: '0.75rem'}} />
            <div className="m-checkbox-inline mb-0  pull-left">
              {arr.map((stage, index) => 
                <div className="checkbox checkbox-dark" key={index}>
                    <Input id={stage.key} type="checkbox" checked={stage.value} onChange={()=>{}} />
                    <Label style={{marginBottom: '0px'}} for={stage.key}>{stage.key}</Label>
                </div>
              )}
              <Label style={{marginBottom: '0px'}}>{'KYC Status : '} {data.kycStatus}</Label>
            </div>

              {/* kycStatus
              startTrading
              submitClientProfile
              submitIbProfile
              submitIbQuestionaire
              isCorporate
              isDeleted */}
          </div>
        </Card>
     
    </Fragment>
  );
};
export default ClientDetailHeader;