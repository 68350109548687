import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, Table } from "reactstrap";
import { InternalTransfers } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { Row } from "react-bootstrap";
import InterModalButton from "./InterModalButton";
import { transactionsService } from "Services";
import TableRowStatus from "CommonElements/Table/TableRowStatus";
import TableRowApprove from "CommonElements/Table/TableRowApprove";
import TableRowReject from "CommonElements/Table/TableRowReject";
import ApprovedModal from "./Approved/ApprovedFormModal";
import RejectModal from "./Reject/RejectFormModal";
import TablePagination from "CommonElements/Table/TablePagination";
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';


const HoverableRowsClass = () => {
  const [approvedModal, setApprovedModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [approvedModalData, setApprovedModalData] = useState([]);
  const [rejectModalData, setRejectModalData] = useState([]);
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  const [rec, setRec] = useState({});
  useEffect(() => {
    getListRecord(1);
  }, []);
  const getListRecord = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    transactionsService
      .getTransactions({ type: "INTERNAL_TRANSFER", page })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  const toggle = (e, rowData) => {
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      setApprovedModalData({ ...rowData });
    }
    setApprovedModal(!approvedModal);
  };

  //Reject Transaction

  const rejecttl = (e, rowData) => {
    if (rowData !== undefined && rowData !== null && rowData.id !== 0) {
      setRejectModalData({ ...rowData });
    }
    setRejectModal(!rejectModal);
  };

  // const rejectTransaction = (id) => {
  //   Swal.fire({
  //     title: "Are you sure you want to Reject?",
  //     cancelButtonText: "No",
  //     confirmButtonText: "Yes",
  //     reverseButtons: true,
  //     showCancelButton: true,
  //     text: "Once rejected, you will not be able to approve this transaction!",
  //     icon: "error",
  //   }).then((res) => {
  //     if (res.isConfirmed) {
  //       alert("Item is Deleted");
  //     }
  //   });
  // };

  // const approveTransaction = () => {
  //   Swal.fire({
  //     title: "Are you sure you want to approve this?",
  //     cancelButtonText: "No",
  //     confirmButtonText: "Yes",
  //     reverseButtons: true,
  //     showCancelButton: true,
  //     text: "Once approve, you will not be able to reject this transaction!",
  //     icon: "success",
  //   });
  // };
  return (
    <Fragment>
      <ApprovedModal
        approvedModal={approvedModal}
        NewMessage="Approved Internal Transfer"
        toggle={toggle}
        defaultVal="Raza Default Value"
        setApprovedModal={setApprovedModal}
        approvedData={approvedModalData}
        refreshRecord={getListRecord}
      />
      <RejectModal
        rejectModal={rejectModal}
        NewMessage="Reject Internal Transfer"
        rejecttl={rejecttl}
        defaultVal="Raza Default Value"
        setRejectModal={setRejectModal}
        rejectData={rejectModalData}
        refreshRecord={getListRecord}
      />
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row>
              <Col md="8">
                <H5>{InternalTransfers}</H5>
              </Col>
              <Col md="4">
                <Row className="float-end">
                  <Col md="6">
                    {getMenuPerm(permissions, 'transactions:create') === 1 &&
                      <InterModalButton btnText="Add" defaultVal="Godo" refreshRecord={getListRecord} />
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">{"#"}</th>
                  <th scope="col">{"Account"}</th>
                  <th scope="col">{"Paid"}</th>
                  <th scope="col">{"Fee"}</th>
                  <th scope="col">{"Gateway"}</th>
                  <th scope="col">{"note"}</th>
                  <th scope="col">{"reason"}</th>
                  <th scope="col">{"Status"}</th>
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              <tbody>
                {rec.data &&
                  rec.data.map((item, id) => (
                    <tr key={id}>
                      <th scope="row">{item.id}</th>
                      <td>
                        {item["Account.login"] ||
                          `${item["AccountFrom.login"]} -> ${item["AccountTo.login"]}`}
                      </td>
                      <td>{item.paid}</td>
                      <td>{item.fee}</td>
                      <td>{item.gateway}</td>
                      <td>{item.note}</td>
                      <td>{item.reason}</td>
                      <td>
                        <TableRowStatus status={item.status} />
                      </td>

                      <td>
                        {item.action}
                        {item.status === 'PENDING' && getMenuPerm(permissions, 'transactions:actions') === 1 &&
                          <a href="#javascript" className="tble_edit">
                            <TableRowApprove onClick={(e) => toggle(e, item)} />
                          </a>
                        }
                        {item.status === 'PENDING' && getMenuPerm(permissions, 'transactions:actions') === 1 &&
                          <a href="#javascript" className="tble_delete">
                            <TableRowReject onClick={(e) => rejecttl(e, item)} />
                          </a>
                        }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TablePagination data={rec} loadData={getListRecord} />

          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
