import React from "react";
import { useNavigate } from "react-router-dom";
import { LogIn, User } from "react-feather";
import { LI, UL, P } from "../../../AbstractElements";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

const UserHeader = () => {
  const history = useNavigate();

  const userProfile = useSelector(
    (state) => (state.login && state.login.profile) || {}
  );

  const Logout = () => {
    localStorage.removeItem("token");
    history(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <LI attrLI={{ className: "profile-nav onhover-dropdown p-0 border-0" }}>
      <div className="media profile-media">
        <div className="media-body">
          <span>
            {userProfile.firstName} {userProfile.lastName}
          </span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {userProfile.Role && userProfile.Role.title != null
              ? userProfile.Role.title
              : "Admin"}
            <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: "profile-dropdown onhover-show-div" }}>
        <LI
          attrLI={{
            className: "border-0",
          }}
        >
          <User />
          <span>
            <Link to={'/profile'}>
              {"Profile"}
            </Link>  
          </span>
        </LI>

        <LI attrLI={{ className: "border-0", onClick: Logout }}>
          <LogIn />
          <span>{"Log Out"}</span>
        </LI>
      </UL>
    </LI>
  );
};

export default UserHeader;
