import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};
export const getReports = (params, type) => {
  let reportUrl = null;
  switch (type) {
    case "deposit":
      reportUrl = "/v1/crm/reports/deposits?" + queryString(params);
      break;
    case "withdrawal":
      reportUrl = "/v1/crm/reports/withdrawals?" + queryString(params);
      break;
    case "funded":
      reportUrl = "/v1/crm/reports/funded-clients?" + queryString(params);
      break;
    case "unFunded":
      reportUrl = "/v1/crm/reports/unfunded-clients?" + queryString(params);
      break;
    default:
      break;
  }
  return api.get(reportUrl, params);
};
