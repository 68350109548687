import React, { Fragment } from "react";
import { Label } from "reactstrap";

const FormInput = ({
  errors = {},
  label = "",
  name = "",
  register = () => {},
  type = "text",
  placeholder = "",
  value = "",
  validation = {},
  handleOnChange,
  disabled,
}) => {
  return (
    <Fragment>
      <Label className="form-label" for="validationCustom01">
        {label}
      </Label>
      <input
        className="form-control"
        id={new Date().getTime().toString()}
        type={type}
        placeholder={placeholder}
        name={name}
        // value={value}
        {...register(name, validation)}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <span>{errors[name] && `${label} is ${errors[name].type}`}</span>
      <div className="valid-feedback">Looks good!</div>
    </Fragment>
  );
};
export default FormInput;
