import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {
  Col,
  Card,
  CardHeader,
  Table,
  Row,
  Container,
  CardBody,
} from 'reactstrap';
import { IBS } from '../../../Constant';
import { H5, Btn } from '../../../AbstractElements';
import { ibService, activitiesService } from 'Services';
import AssignModal from './Modal/assignTo';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import IbModalButton from './IbModalButton';
import ModalButton from '../../Common/Data/Ui-kits/Modals/VaryingContent/common/ModalButton';
import { Link } from 'react-router-dom';
import TablePagination from 'CommonElements/Table/TablePagination';
import { useSelector } from 'react-redux';
import { getMenuPerm } from 'utils';
import { writeFile, utils } from 'xlsx';
import DataTable from 'react-data-table-component';
const HoverableRowsClass = () => {
  const [rec, setRec] = useState({});
  const [modal, setModal] = useState(false);
  const [ibBulkModal, setIbBulkModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [modalMessage, setmodalMessage] = useState('');
  useEffect(() => {
    loadData(1);
  }, []);
  const loadData = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    ibService
      .getIbs({
        page,
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log('err ', err);
      });
  };
  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );
  const emailPhoneViewClick = (e, index, customerId, type) => {
    e.preventDefault();
    var updatedRecord = rec;
    var isActive = false;
    var activityType = '';
    if (type === 'emailView') {
      activityType = 'email';
      isActive = updatedRecord.data[index].isEmailView === true ? false : true;
      updatedRecord.data[index].isEmailView = updatedRecord.data[
        index
      ].isEmailView = isActive;
    } else if (type === 'phoneView') {
      activityType = 'phone';
      isActive = updatedRecord.data[index].isPhoneView === true ? false : true;
      updatedRecord.data[index].isPhoneView = updatedRecord.data[
        index
      ].isPhoneView = isActive;
    }
    if (isActive) {
      var postData = {
        type: activityType,
        customerId: customerId,
        note: activityType + ' View ',
      };
      postActivities(postData);
    }
    setRec({
      ...updatedRecord,
      loading: false,
    });
  };
  const postActivities = (payload) => {
    activitiesService
      .postActivity(payload)
      .then((res) => {})
      .catch((err) => {
        console.log('err ', err);
      });
  };
  const clearSelectedRow = () => {
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };
  const tableColumns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: false,
      center: true,
    },
    {
      name: 'Name',
      sortable: false,
      center: true,
      cell: (item) => (
        <Link className='' to={`/ibs/${item.id}`}>
          {`${item.firstName} ${item.lastName}`}
        </Link>
      ),
    },
    {
      name: 'Email',
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isEmailView ? (
            <span>
              ******
              <i
                className='fa fa-eye-slash'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'emailView')
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.email}
              <i
                className='fa fa-eye'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'emailView')
                }
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: 'Phone',
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isPhoneView ? (
            <span>
              ******
              <i
                className='fa fa-eye-slash'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'phoneView')
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.phone}
              <i
                className='fa fa-eye'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'phoneView')
                }
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: 'Country',
      selector: (row) => row.country,
      sortable: false,
      center: true,
    },
    {
      name: 'Sales',
      // selector: (row) => row.country,
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {item['Agent.firstName']} {item['Agent.lastName']}
        </div>
      ),
    },
    {
      name: 'IB',
      selector: (row) => row.ib,
      sortable: false,
      center: true,
    },
    {
      name: 'KYC',
      selector: (row) => row.kycStatus,
      sortable: false,
      center: true,
    },
  ];
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);
  const toggle = (e) => {
    if (e.target.textContent == 'Sales Assign') {
      setmodalMessage('Assign to');
    } else if (e.target.textContent == 'Delete IBs') {
      setmodalMessage('Delete IBs');
    }
    setModal(!modal);
  };
  const clientUploadToggle = (e) => {
    setIbBulkModal(!ibBulkModal);
  };
  function exportToExcel(data) {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'ib_sheet');
    return workbook;
  }
  const handleExport = () => {
    const workbook = exportToExcel(rec.data);
    writeFile(workbook, 'ib_list.xlsx');
  };
  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md='4'>
                <H5>{IBS}</H5>
              </Col>
              <Col md='8'>
                <Row>
                  {selectedRows.length > 0 ? (
                    <Col md='3'>
                      <Btn attrBtn={{ color: 'primary', onClick: toggle }}>
                        Sales Assign
                      </Btn>
                      <AssignModal
                        modal={modal}
                        toggle={toggle}
                        setModal={setModal}
                        selectedSales={selectedRows}
                        checkBoxClear={clearSelectedRow}
                        loadIbData={loadData}
                        titleMessage={modalMessage}
                      />
                    </Col>
                  ) : (
                    <Col md='3'></Col>
                  )}

                  {selectedRows.length > 0 ? (
                    <Col md='3'>
                      <Btn
                        attrBtn={{
                          color: 'secondary',
                          onClick: toggle,
                        }}
                      >
                        Delete IBs
                      </Btn>
                    </Col>
                  ) : (
                    <Col md='3'></Col>
                  )}
                  {/* <Col md='2'>
                    <Btn
                      attrBtn={{
                        color: 'btn btn-outline-primary ms-2',
                        onClick: clientUploadToggle,
                      }}
                    >
                      <i
                        className='fa fa-upload'
                        style={{ padding: '3px' }}
                      ></i>
                      <ClientModal
                        modal={ibBulkModal}
                        NewMessage='Upload Clients'
                        toggle={clientUploadToggle}
                        setModal={setIbBulkModal}
                      />
                      Upload
                    </Btn>
                  </Col> */}
                  {rec.data ? (
                    <Col md={'3'}>
                      <Btn
                        attrBtn={{ color: 'success', onClick: handleExport }}
                      >
                        Export
                      </Btn>
                    </Col>
                  ) : null}
                  <Col md={'3'}>
                    {getMenuPerm(permissions, 'ibs:create') === 1 && (
                      <IbModalButton btnText='Add' defaultVal='Godo' />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className='table-responsive'>
            <Container fluid={true} className='datatables'>
              <Row>
                <Col sm='12'>
                  <Card>
                    {rec.data ? (
                      <CardBody>
                        <DataTable
                          data={rec.data}
                          columns={tableColumns}
                          striped={true}
                          center={true}
                          selectableRows
                          persistTableHead
                          onSelectedRowsChange={handleRowSelected}
                          clearSelectedRows={toggleCleared}
                        />
                      </CardBody>
                    ) : null}
                  </Card>
                </Col>
              </Row>
            </Container>
            <TablePagination data={rec} loadData={loadData} />
          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
