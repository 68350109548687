import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import CKEditors from 'react-ckeditor-component';
import { useSelector } from "react-redux";

import { systemEmail } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const AddEmail = ({ loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
  };
  const { register, handleSubmit, formState: { errors }, control, reset, setValue } = useForm();
  const EMAIL_ACTIONS = useSelector(
    (state) => (state?.config.constants?.EMAIL_ACTIONS) || {}
  );

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        const params = {...formValues};
        if(params.fields) {
            params.fields = params.fields.split(',');
        }
        systemEmail.addEmail(params).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Email added, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to add Email');
        });
    } else {
        errors.showMessages();
    }
}   ;
const onBodyChange = (evt) => {
    const newContent = evt.editor.getData();
    setValue('bodyEn', newContent)
}
  return (
    <Fragment>
        <Btn attrBtn={{ disabled:loading, onClick:toggle, color: 'primary' }} >{'Add Email'}</Btn>

        <CommonModal
            isOpen={modal}
            title={'Add New System Email'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
            size={'lg'}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Email Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Enter Email Title'
                                validation={{required: true}}
                            />
                        </Col>
                        {/* <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Email Fields (Comma Separated)'
                                name = 'fields'
                                register = {register}
                                placeholder= 'Enter Email Fields'
                                validation={{required: true}}
                            />
                        </Col> */}
                        <FSelect
                                control={control}
                                errors={errors}
                                label = 'Email Action On'
                                name = 'action'
                                register = {register}
                                placeholder= 'Select Email Action'
                                options = {Object.keys(EMAIL_ACTIONS).map((key) => {
                                    return {
                                        name: key,
                                        value: key,
                                    }
                                })}
                                validation={{required: true}}
                                value={''}
                                setValue={setValue}
                            />
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Email Subject'
                                name = 'subjectEn'
                                register = {register}
                                placeholder= 'Enter Email subject'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <CKEditors
                                    activeclassName="p10"
                                    content={''}
                                    events={{
                                        'change': onBodyChange
                                    }}
                                />
                        </Col>
                        
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Add'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default AddEmail;
