import React from "react";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import TableProvider from "./_helper/Table/TableProvider";
import TransactionsProvider from "./_helper/Transactions/TransactionsProvider";
import RequestsProvider from "./_helper/Requests/RequestsProvider";
import ClientsProvider from "./_helper/Clients/ClientsProvider";
import TodoProvider from "./_helper/Todo/TodoProvider";

import { Provider } from "react-redux";

// Bring the redux store too
import store from "./redux/store";

const App = () => (
  <div className="App">
    <Provider store={store}>
      <CustomizerProvider>
        <TodoProvider>
          <TableProvider>
            <TransactionsProvider>
              <RequestsProvider>
                <ClientsProvider>
                  <AnimationThemeProvider>
                    <Routers />
                  </AnimationThemeProvider>
                </ClientsProvider>
              </RequestsProvider>
            </TransactionsProvider>
          </TableProvider>
        </TodoProvider>
      </CustomizerProvider>
    </Provider>
  </div>
);

export default App;
