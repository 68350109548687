import React, { Fragment } from 'react';
import { Table, Row, Col } from 'reactstrap';
import TableNodata from 'CommonElements/Table/TableNodata';

const OpenPositions = ({ positions = {} }) => {
  const {
    data = []
  } = positions;
  if (!positions || !positions.data) return ('');
  return (
    <Fragment>
        
        <Row>
            <Col md={12} className="mt-5 ">
                <h5>Open Positions</h5>
            </Col>
        </Row>
         <div className="table-responsive">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">Login</th>
                        <th scope="col">Symbol</th>
                        <th scope="col">Position</th>
                        <th scope="col">Time</th>
                        <th scope="col">Deal Type</th>
                        <th scope="col">Lots</th>
                        <th scope="col">Price</th>
                        <th scope="col">Price SL</th>
                        <th scope="col">Price TP</th>
                        <th scope="col">Price Position</th>
                        <th scope="col">Swap</th>
                        <th scope="col">Profit</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, id) =>
                            <tr key={id}>
                                <td >{item.Login || item.login}</td>
                                <td>{item.Symbol || item.symbol}</td>
                                <td>{item.Ticket || item.ticket}</td>
                                <td>{item.Time || item.open_time}</td>
                                <td>{item['DealType'] || item.type_str}</td>
                                <td>{item.Volume || item.volume}</td>
                                <td>{item.Price || item.open_price}</td>
                                <td>{item['PriceSL'] || item.sl}</td>
                                <td>{item['PriceTP'] || item.tp}</td>
                                <td>{item['PricePosition'] || item.close_price}</td>
                                <td>{item.Swap || item.swap}</td>
                                <td>{item.Profit || item.profit}</td>
                            </tr>
                        )
                    }
                    
                </tbody>
                {
                        positions.data && positions.data.length === 0 && 
                        <TableNodata title="Open Positions" />
                        
                    }
            </Table>
        </div>
    </Fragment>
  );
};
export default OpenPositions;