import React, { Fragment, useState } from 'react';
import { Label, FormGroup } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { userService } from 'Services';
import AsyncSelect from 'react-select/async';

const FormInput = ({
    errors = {},
    label = '',
    name = '',
    validation={},
    value: defValue,
    setValue = () => {},
    control,
    defaultOptions=[]
}) => {
  const [, setSelectedValue] = useState( null);

 
  // handle selection
  const handleChange = e => {
    console.log('new value ', e);
    setSelectedValue(e);
    setValue(name, e.id);

  }
 
  // load options using API call
  const loadOptions = (inputValue) => {
    return new Promise(async(resolve , reject) => {
      return userService.getUsersDropdown(inputValue).then(res => {
        return resolve(res);
      }).catch(err => {
        resolve([])
      });
    })

  };
  return (
    <Fragment>
        <FormGroup>
            <Label className="form-label" for={name}>{label}</Label>
          {control && 
            <Controller
                  control={control}
                  defaultValue={defValue}
                  name={name}
                  id={name}
                  // className="form-select" 
                  isSearchable={true}
                  isClearable
                  // className="react-dropdown"
                  // classNamePrefix="dropdown"
                  // {...register(name, validation)}
                  rules={validation}
                  render={({ 
                    field: { onChange, onBlur, value, name, ref }
                  }) => (
                    <AsyncSelect
                    // defaultInputValue={defValue && defValue.id}
                    cacheOptions
                    defaultOptions={defaultOptions}
                    // value={selectedValue}
                    value={defaultOptions && defaultOptions.find(c => c.id === value)}
                    getOptionLabel={e => `${e.firstName} ${e.lastName}`}
                    getOptionValue={e => e.id}
                    loadOptions={loadOptions}
                    // onInputChange={handleUpdate}
                    onChange={(e)=>{onChange(e); handleChange(e)}}
                  />
                      
                  )}
              />
          //     <AsyncSelect
          //     inputRef={ref}
          //     onBlur={onBlur} // notify when input is touched
          //     classNamePrefix="addl-class"
          //     // options={newOptions}
          //     loadOptions={searchClients} 
          //     value={newOptions.find(c => c.value === value)}
          //     onChange={(e)=>{onChange(e); handleUpdate(e)}}
          // />
            }
            <span className='error'>{errors[name] && `${label} is ${errors[name].type}`}</span>
        </FormGroup>
    </Fragment>
  );
};
export default FormInput;
