import React, { Fragment } from "react";
import { Label, FormGroup } from "reactstrap";
import Select from "react-select";
import { Controller } from "react-hook-form";

const FormInput = ({
  errors = {},
  label = "",
  name = "",
  register = () => {},
  placeholder = "",
  options = [],
  validation = {},
  value: defValue,
  setValue = () => {},
  control,
  disabled = false,
  isMultiSelect = false,
}) => {
  const handleUpdate = (e) => {
    setValue(name, e.value);
  };
  const newOptions = options.map((obj) => {
    return {
      ...obj,
      label: obj.name,
    };
  });
  return (
    <Fragment>
      <FormGroup>
        <Label className="form-label" for={name}>
          {label}
        </Label>
        {control && (
          <Controller
            control={control}
            defaultValue={defValue}
            name={name}
            id={name}
            // className="form-select"
            isSearchable={false}
            isClearable
            // className="react-dropdown"
            // classNamePrefix="dropdown"
            // {...register(name, validation)}
            rules={validation}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <Select
                isDisabled={disabled}
                inputRef={ref}
                onBlur={onBlur} // notify when input is touched
                classNamePrefix="addl-class"
                options={newOptions}
                value={newOptions.find((c) => c.value === value)}
                isMulti={isMultiSelect}
                placeholder={placeholder}
                onChange={(e) => {
                  onChange(e);
                  handleUpdate(e);
                }}
              />
            )}
          />
        )}
        <span className="error">
          {errors[name] && `${label} is ${errors[name].type}`}
        </span>
      </FormGroup>
    </Fragment>
  );
};
export default FormInput;
