import api from './api';
var queryString = (params) => {
    return Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
  };
  
export const getAccounts = (customerId, exludeState = false)=> {
    return api.get('/v1/crm/accounts?customerId='+customerId+'&exludeState='+exludeState)
}
export const createAccount = (params)=> {
    return api.post('/v1/crm/accounts', params)
}

export const linkAccount = (params)=> {
    return api.post('/v1/crm/accounts/link', params)
}

export const getOpenPositions = (login, page = 1, limit = 10)=> {
    return api.get('/v1/crm/accounts/'+login+'/open-positions', queryString({page, limit}))
}
