import React, { Fragment, useState } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, H6, P, Alerts } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import * as AuthService from "../../../Services/auth.service";

const ForgetPwd = () => {
    const [phase, setPhase] = useState(1);
    const [email, setEmail] = useState("");
    const [phase1Message, setPhase1Message] = useState("");
    const sendOtpClicked = (e) => {
        AuthService.sendForgetPasswordOtp(email).then(res => {
            setPhase1Message('OTP sent to your email, please enter OTP, and new Password')
            setPhase(2);
            setEmail('');
        }).catch(err => {
            setPhase1Message(err?.response?.data?.message || 'Error is sending OTP, please contact administrator')
        })
        e.preventDefault();
        return false;
    }

    const [secondForm, setSecondForm] = useState({});
    const setFormV = (key, value) => {
        setSecondForm({
            ...secondForm,
            [key]: value,
        })
    }
    const [phase2Message, setPhase2Message] = useState("");
    const changePasswordSubmit = (e) => {
        AuthService.changePasswordByOtp(secondForm).then(res => {
            setPhase2Message('Password Changed Succesfully')
            setSecondForm({})
            setTimeout(()=>{
                document.location = "/login";
            },2000)
        }).catch(err => {
            setPhase2Message(err?.response?.data?.message || 'Error in changing password, try again.')
        })
        e.preventDefault();
        return false;
    }

    
    return (
        <Fragment>
            <section>
                <Container className='p-0' fluid={true}>
                    <Row className="m-0">
                        <Col className="p-0">
                            <div className="login-card">
                                <div className="login-main">
                                    {phase === 1 &&
                                        <Form className="theme-form login-form" onSubmit={sendOtpClicked}>
                                            <H4 attrH4={{ className: "mb-3" }}>Reset Your Password</H4>
                                            <FormGroup>
                                                <Label>Enter Your Registered Email Address</Label>
                                                <Row>
                                                    <Col xl="12" sm="12">
                                                        <Input onChange={(e) => setEmail(e.target.value)} className="form-control" type="email" placeholder="Enter you email" required />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup className="text-center">
                                                <Btn attrBtn={{ className: "btn-block", color: 'primary', type: "submit"  }} >Send OTP</Btn>
                                            </FormGroup>
                                            <P>
                                                Already have an password?
                                                <Link to={'/login'}>
                                                    Login
                                                </Link>
                                            </P>
                                            {phase1Message && <Alerts attrAlert={{className: 'alert alert-light', role: 'alert'}} >
                                                {phase1Message}
                                            </Alerts>}
                                            
                                        </Form>
                                    }
                                    {phase === 2 &&
                                        <Form className="theme-form login-form" onSubmit={changePasswordSubmit}>
                                            <H4 attrH4={{ className: "mb-3" }}>Reset Your Password</H4>
                                            {/* <FormGroup> <span className="reset-password-link">If don't receive OTP?  <a className="btn-link text-danger" href="#javascript">Resend</a></span></FormGroup> */}
                                            <FormGroup>
                                                <Label>Enter OTP</Label>
                                                <Row>
                                                    <Col>
                                                        <Input onChange={(e) => setFormV('otp', e.target.value)} className="form-control text-center opt-text" type="text" maxlength="6" name="otp" minLength={6} placeholder="Enter OTP you received in your Email" required />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <H6>Create Your Password</H6>
                                            <FormGroup className="position-relative">
                                                <Label>New Password</Label>
                                                <div className="input-group"><span className="input-group-text"><i className="icon-lock"></i></span>
                                                    <Input onChange={(e) => setFormV('password', e.target.value)} className="form-control" type="password" name="login[password]1" required="" placeholder="*********" />
                                                    <div className="show-hide"><span className="show"></span></div>
                                                </div>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Retype Password</Label>
                                                <div className="input-group"><span className="input-group-text"><i className="icon-lock"></i></span>
                                                    <Input onChange={(e) => setFormV('confirmPassword', e.target.value)} className="form-control" type="password" name="login[password]2" required="" placeholder="*********" />
                                                </div>
                                            </FormGroup>
                                            <FormGroup className="text-center">
                                                <Btn attrBtn={{ class: "btn btn-primary btn-block", type: "submit" }} >Update Password</Btn>
                                            </FormGroup>
                                            {phase2Message && <Alerts attrAlert={{className: 'alert alert-light', role: 'alert'}} >
                                                {phase2Message}
                                            </Alerts>}
                                        </Form>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default ForgetPwd;