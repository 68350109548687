import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader = () =>(
    <tbody>
        <tr>
            <td className='text-center ' colSpan={"100%"}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </td>
            
        </tr>
    </tbody>
);

export default Loader;