import React from 'react';
import { Badges } from 'AbstractElements';

const TableRowEdit = ({status=''}) => {
    let clr = '';
    switch(status) {
        case 'Pending':
        case 'PENDING':
            clr = 'primary';
            break;
        case 'Approved':
        case 'APPROVED':
            clr = 'success';
            break;
        case 'Rejected':
        case 'REJECTED':
        case 'Expired':
        case 'EXPIRED':
            clr = 'danger';
            break;
        default:
            clr = '';
            break;
    }
    return (
        <React.Fragment>

            <Badges attrBadge={{ color: clr }} >{status}</Badges>
        </React.Fragment>
    );
};

export default TableRowEdit;