import React, { Fragment } from 'react';
import { Label, Input } from 'reactstrap'

const FormInput = ({
    errors = {},
    label = '',
    name = '',
    register = () => {},
    setValue = () => {},
    value=false
}) => {
  return (
    <Fragment>
        <Label className="d-block" for={name}>
            <Input defaultChecked={value} onChange={(e)=>{setValue(name, e.target.checked)}} className="checkbox_animated" name={name} id={name} type="checkbox"  />{label}
        </Label>
    </Fragment>
  );
};
export default FormInput;