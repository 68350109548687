import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, Form, Row, CardHeader } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FSelect, FCheckbox, FUserSelect } from 'CommonElements/Form';
import { clientService } from 'Services';
import SweetAlert from 'sweetalert2';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';

const BasicInformation = (props) => {
  const {
    customerId = '',
    data = {},
  } = props;
  const COUNTRIES = useSelector(
    (state) => (state.config.constants.COUNTRIES )
  );
  const [submitState, setSubmitState] = useState({
    loading: false,
  })
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const { register, handleSubmit, formState: { errors }, setValue, getValues, control } = useForm();
  useEffect(()=>{
    const formFields = getValues();
    Object.keys(formFields).forEach(key => {
        console.log('key ', key, data[key])
      if(data[key]) {
        setValue(key, data[key])
      }
    })
  }, [])



  const onSubmit = data => {
    console.log('daa ', data, getValues());
    if (data !== '') {
        setSubmitState({loading: true});
        clientService.updateClient(customerId, data).then(res => {
            setSubmitState({
                loading: false,
                status: 'Profile updated sucessfully'
            })
            SweetAlert.fire({ title: 'Success', text: 'Profile updated sucessfully!', icon: 'success' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'success' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'error' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'info' });
            // SweetAlert.fire({ title: 'Good job!', text: 'You clicked the button!', icon: 'warning' });

        }).catch(err => {
            setSubmitState({
                loading: false,
                status: 'Error in updating Profile'
            })
            SweetAlert.fire({ title: 'Failed', text: 'Error in updating Profile!', icon: 'error' });
        });
        // alert('You submitted the form and stuff!');
    } else {
        errors.showMessages();
    }
};

const aggrements = [
    {name: 'Accepted Terms & Conditions', value: 'acceptedTerms'},
    {name: 'Accepted Mandatory Information', value: 'acceptedMandatoryInfo'},
    {name: 'Accepted Risk Disclosure', value: 'acceptedRisk'},
    {name: 'Accepted Conflicts Of Interest Policy', value: 'acceptedConflicts'},
    {name: 'Source(s) Of Income', value: 'acceptedSourceIncome'},
    {name: 'Accepted Client Categorization Policy', value: 'acceptedCategoriztionPolicy'},
    {name: 'Accepted Privacy Policy', value: 'acceptedPrivacy'},
    {name: 'Accepted Order Execution Policy', value: 'acceptedExecutionPolicy'},
    {name: 'Accepted Statement', value: 'acceptedStatenebt'},
    {name: 'Accepted Client Agreement', value: 'acceptedCliebntAgrement'},
    {name: 'Appropriateness risk warning', value: 'acceptedWarning'},
    {name: 'Accept cookies policy', value: 'acceptedCookies'},
];

  return (
    <Fragment>
        <Card>
            <CardHeader className='p-3'>
                Basic Information Individual
            </CardHeader>
            <CardBody>
                <Form  className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'First Name'
                                name = 'firstName'
                                register = {register}
                                placeholder= 'Enter First Name'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Last Name'
                                name = 'lastName'
                                register = {register}
                                placeholder= 'Enter Last Name'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Email'
                                name = 'email'
                                register = {register}
                                placeholder= 'Enter Email Address'
                                validation={{required: true}}
                            />
                        </Col>
                        
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Phone'
                                name = 'phone'
                                register = {register}
                                placeholder= 'Enter Phone Number'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Mobile'
                                name = 'mobile'
                                register = {register}
                                placeholder= 'Enter Mobile'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FUserSelect
                                control={control}
                                errors={errors}
                                label = 'Select Agent'
                                name = 'agentId'
                                validation={{required: true}}
                                setValue={setValue}
                                value={data.agentId}
                                defaultOptions={data.Agent ? [data.Agent]:[]}
                                
                                // isClient={true}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Retention'
                                name = 'retention'
                                register = {register}
                                placeholder= 'Enter Retention'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Account Status'
                                name = 'accountStatus'
                                register = {register}
                                placeholder= 'Select Account Status'
                                options = {[
                                    {name: 'Pending', value: 'Pending ' },
                                    {name: 'Rejected', value: 'Rejected'},
                                    {name: 'Approved', value: 'Approved'},
                                    {name: 'Frozen', value: 'Frozen'},
                                    {name: 'Closed', value: 'Closed ' },
                                    {name: 'Blacklisted', value: 'Blacklisted ' },
                                ]}
                                
                                validation={{required: true}}
                                value={data.accountStatus}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Account Stage'
                                name = 'accountStage'
                                register = {register}
                                placeholder= 'Select Account Stage'
                                options = {[
                                    {name: 'Inactive', value: 'Inactive'},
                                    {name: 'Funded', value: 'Funded'},
                                    {name: 'Traded', value: 'Traded'},
                                    {name: 'Dormant', value: 'Dormant'},
                                    {name: 'Frozen', value: 'Frozen'},
                                    {name: 'Closed', value: 'Closed'},
                                    {name: 'Blacklisted', value: 'Blacklisted'},
                                ]}
                                
                                validation={{required: true}}
                                value={data.accountStatus}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Is US Citizen?'
                                name = 'fatca'
                                setValue={setValue}
                                value={data.fatca}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Is Trade Disabled?'
                                name = 'tradeDisabled'
                                setValue={setValue}
                                value={data.tradeDisabled}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Is Compliance Completed?'
                                name = 'complianceCompleted'
                                setValue={setValue}
                                value={data.complianceCompleted}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Receive Newsletters?'
                                name = 'newsLetters'
                                setValue={setValue}
                                value={data.newsLetters}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'IP'
                                name = 'ip'
                                register = {register}
                                placeholder= 'IP'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Email Opt Out?'
                                name = 'emailOptOut'
                                setValue={setValue}
                                value={data.emailOptOut}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Last Login'
                                name = 'lastLogin'
                                register = {register}
                                placeholder= 'lastLogin'
                                type='text'
                                // value={data.lastLogin}
                                disabled
                            />
                            
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Address'
                                name = 'address'
                                register = {register}
                                placeholder= 'Enter Address'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Prefix'
                                name = 'address2'
                                register = {register}
                                placeholder= 'Prefix'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'City'
                                name = 'city'
                                register = {register}
                                placeholder= 'Enter City'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'State'
                                name = 'state'
                                register = {register}
                                placeholder= 'Enter State'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Zip Code'
                                name = 'zipCode'
                                register = {register}
                                placeholder= 'Enter Zip Code'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Country of Residence'
                                name = 'country'
                                register = {register}
                                placeholder= 'Enter Country of Residence'
                                validation={{required: true}}
                                value={data.country}
                                options = {COUNTRIES && COUNTRIES.map(obj => {
                                    return {
                                        name: obj.text,
                                        value: obj.text
                                    }
                                })}  
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Nationality'
                                name = 'nationality'
                                register = {register}
                                placeholder= 'Enter Nationality'
                                validation={{required: true}}
                                value={data.nationality}
                                options = {COUNTRIES && COUNTRIES.map(obj => {
                                    return {
                                        name: obj.text,
                                        value: obj.text
                                    }
                                })}  
                                setValue={setValue}
                            />
                            
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Date of Birth'
                                name = 'dob'
                                register = {register}
                                placeholder= 'Enter Date of Birth'
                                type="date"
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Country of Birth'
                                name = 'countryOfBirth'
                                register = {register}
                                placeholder= 'Country of Birth'
                                validation={{required: true}}
                                value={data.countryOfBirth}
                                options = {COUNTRIES && COUNTRIES.map(obj => {
                                    return {
                                        name: obj.text,
                                        value: obj.text
                                    }
                                })}  
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Education Level'
                                name = 'educationLevel'
                                register = {register}
                                placeholder= 'Education Level'
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Employment Status'
                                name = 'employmentStatus'
                                register = {register}
                                placeholder= 'Employment Status'
                                options = {[{name: 'Employed', value: 'employed'},{name: 'UnEmployed', value: 'unemployed'}]}
                                validation={{required: true}}
                                value={data.employmentStatus}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Profession'
                                name = 'profession'
                                register = {register}
                                placeholder= 'Enter Profession'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Name of Own Company'
                                name = 'ownCompany'
                                register = {register}
                                placeholder= 'Enter Name of Own Company'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Country of Tax Residency'
                                name = 'taxResidency'
                                register = {register}
                                placeholder= 'Enter Country of Tax Residency'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Tax ID, NTN'
                                name = 'taxIdentificationNumber'
                                register = {register}
                                placeholder= 'Enter Tax ID'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Source Of Funds'
                                name = 'sourceOfFunds'
                                register = {register}
                                placeholder= 'Enter Source Of Funds'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'CFD Trading Frequency'
                                name = 'cfdFrequency'
                                register = {register}
                                placeholder= 'Enter CFD Trading Frequency'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Forex Trading Frequency'
                                name = 'forexFrequency'
                                register = {register}
                                placeholder= 'Enter Forex Trading Frequency'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Other Derivatives Frequency'
                                name = 'otherDerivativesFrequency'
                                register = {register}
                                placeholder= 'Enter Other Derivatives Frequency'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Shares/Bonds Frequency'
                                name = 'sharesFrequency'
                                register = {register}
                                placeholder= 'Enter Shares/Bonds Frequency'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Converted From Lead?'
                                name = 'leadConverted'
                                setValue={setValue}
                                value={data.leadConverted}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Enter Source'
                                name = 'source'
                                register = {register}
                                placeholder= 'Enter Source'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FCheckbox
                                label = 'Is Politically Exposed?'
                                name = 'politicallyExposed'
                                setValue={setValue}
                                value={data.politicallyExposed}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Annual Income'
                                name = 'annualIncome'
                                register = {register}
                                placeholder= 'Enter Annual Income'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Net Worth'
                                name = 'netWorth'
                                register = {register}
                                placeholder= 'Enter Net Worth'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Purpose of Investment'
                                name = 'investmentPurpose'
                                register = {register}
                                placeholder= 'Enter Purpose of Investment'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Risk Level'
                                name = 'riskLevel'
                                register = {register}
                                placeholder= 'Enter Risk Level'
                                validation={{required: true}}
                            />
                        </Col>
                        
                        {aggrements.map((agg) => 
                             <Col md='4 mb-3' > <FCheckbox
                                label = {agg.name}
                                name = {agg.value}
                                setValue={setValue}
                                value={data[agg.value]}
                                />
                            </Col>
                        )}
<Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Affiliate ID'
                                placeholder= 'Affiliate ID'
                                name = 'affiliateId'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Campaign'
                                placeholder= 'UTM Campaign'
                                name = 'utmCampaign'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Source'
                                placeholder= 'UTM Source'
                                name = 'utmSource'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Medium'
                                placeholder= 'UTM Medium'
                                name = 'utmMedium'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Term'
                                placeholder= 'UTM Term'
                                name = 'utmTerm'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Content'
                                placeholder= 'UTM Content'
                                name = 'utmContent'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'UTM Category'
                                placeholder= 'UTM Category'
                                name = 'utmCategory'
                                register = {register}
                            />
                        </Col>
                        <Col md='4 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Website'
                                placeholder= 'Website'
                                name = 'website'
                                register = {register}
                            />
                        </Col>

                        


                        
                        
                        
                        
                    </Row>
                    

                    {getMenuPerm(permissions, 'clients:update') === 1 &&
                        <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                    }
                </Form>
            </CardBody>
        </Card>
    </Fragment>
  );
};
export default BasicInformation;
