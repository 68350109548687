import React, { Fragment, useEffect, useState } from 'react';
import { tradingAccountsService, accountTypeService } from 'Services';
import { Col, Row, Table,Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import AddAccount from './AddAccount';
import OpenPositions from './OpenPositions';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import LinkAccount from './LinkAccount';

const TradingAccounts = (props) => {
    
  const {
    customerId = '',
    activeTab, tabNumber
  } = props;
  const [rec, setRec] = useState({});
  const [positions, setPositions] = useState({
    loading: false,
    openPositions: [],
    closePositions: []
  });
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  const [accountTypes, setAccountTypes] = useState([]);

  useEffect(()=>{
    if(activeTab === tabNumber) {
        accountTypeService.getAccountTypes().then(res => {
            if(res && res.data) {
                setAccountTypes(res.data);
            }
        }).catch(err => {

        })
        loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = async () => {
    tradingAccountsService.getAccounts(customerId).then((res) => {
        console.log(res);
        setRec({
            data: [],
            ...res,
            loading: false,
        })
        }).catch(err =>{
            console.log('err ', err);
        });
  }

  const loadPositions = async (item, id) => {
    setPositions({loading: true});
    /*if(item.platform === 'MT4'){
        const mt4Response = await axios.get(`http://mt5mngr.godofx.com:6502/v1/trades/online/100440?token=cab267ec43e397dfc7ad277a5f6efbc77249047f537754d579142ba63606456a`);
        const mt4AccountState = mt4Response.data; // Assuming that mt4Response.data contains the MT4 account state
        console.log('mt4 positions are ',mt4AccountState);
    }
    else{*/
        try {
            const openPositions = await tradingAccountsService.getOpenPositions(item.id);
            console.log('posossdofsja');
            console.log(openPositions);
            console.log('hmmm');
            setPositions({loading: false, openPositions});
        } catch (err) {
            setPositions({loading: false});
        }
    /*}*/
  }
  const pageSize = 10; // Number of items per page
  const totalPages = Math.ceil((rec.data && rec.data.length / pageSize));

  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedData = (rec.data && rec.data.slice(startIndex, endIndex));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const paginationStyle = {
    padding: '30px 0px', // Add your desired padding values
  };
  return (
    <Fragment>
        <Row>
        <Col md={12} className="d-flex flex-row-reverse">
                {getMenuPerm(permissions, 'accounts:create') === 1 &&
                    <AddAccount customerId={customerId} accountTypes={accountTypes} loadData={loadData} />
                }
                &nbsp; &nbsp;
                {getMenuPerm(permissions, 'accounts:create') === 1 &&
                    <LinkAccount customerId={customerId} accountTypes={accountTypes} loadData={loadData} />
                }
            </Col>
        </Row>
         <div className="table-responsive">
            <Table hover>
                <thead>
                    <tr>
                        <th scope="col">{'#'}</th>
                        <th scope="col">{'Login'}</th>
                        <th scope="col">{'Type'}</th>
                        <th scope="col">{'Account Type'}</th>
                        <th scope="col">{'Balance'}</th>
                        <th scope="col">{'Credit'}</th>
                        <th scope="col">{'Margin Free'}</th>
                        <th scope="col">{'Equity'}</th>
                        <th scope="col">{'Leverage'}</th>
                        
                    </tr>
                </thead>
                {rec.loading && <TableLoader />}
                {!rec.loading && displayedData && displayedData.length === 0 && <TableNodata title="Accounts" />}
                
                <tbody>
                    {
                       displayedData && displayedData.map((item, id) =>
                            <tr key={id}>
                                {console.log(item)}
                                <th scope="row">{item.id}</th>
                                <td >
                                    <strong style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>{loadPositions(item, id)}}>{item.login}</strong>
                                </td>
                                <td >{item.type}</td>
                                <td >{item['AccountType.title']}</td>
                                <td >{(item.state && item.state.Balance) || (item.state && item.state.margin && item.state.margin.balance)}</td>
                                <td >{item.state && item.state.Credit}</td>
                                <td >{(item.state && item.state.MarginFree) || (item.state && item.state.margin && item.state.margin.margin_free)}</td>
                                <td >{(item.state && item.state.Equity) || (item.state && item.state.margin && item.state.margin.equity)}</td>
                                <td >{(item.state && item.state.MarginLeverage) || (item.state && item.state.margin && item.state.margin.leverage)}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            <Pagination style={paginationStyle}>
                {Array.from({ length: totalPages }, (_, index) => (
                <PaginationItem key={index} active={currentPage === index + 1}>
                    <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                    </PaginationLink>
                </PaginationItem>
                ))}
            </Pagination>
        </div>
        <OpenPositions positions={positions.openPositions} />
    </Fragment>
  );
};
export default TradingAccounts;