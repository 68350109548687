import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import CKEditors from 'react-ckeditor-component';

import { systemEmail } from 'Services';
import TableRowEdit from 'CommonElements/Table/TableRowEdit';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";
import { useSelector } from "react-redux";

const EditSystemEmail = ({ data={},  loadData  }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setTimeout(()=>{
        setFormFields();
    },1)
  };
  const { register, handleSubmit, formState: { errors }, control, setValue } = useForm();
  
  // for adding data values to form
  const setFormFields = () => {
    setValue('title', data.title);
    setValue('subjectEn', data.subjectEn);
    setValue('bodyEn', data.bodyEn);
    setValue('action', data.action);
  }
  const EMAIL_ACTIONS = useSelector(
    (state) => (state?.config.constants?.EMAIL_ACTIONS) || {}
  );

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);
        delete formValues.title;
        systemEmail.updateEmail(data.id, formValues).then(res => {
            setLoading(false);
            toggle();
            toast.success('Email updated, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error(err?.response?.data?.message || 'Failed to update Email');
        });
    } else {
        errors.showMessages();
    }
};

const onBodyChange = (evt) => {
    const newContent = evt.editor.getData();
    setValue('bodyEn', newContent)
}
  return (
    <Fragment>
        <TableRowEdit onClick={toggle} />

        <CommonModal
            isOpen={modal}
            title={'Edit System Email'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
            size={'lg'}
        >
            {/* {JSON.stringify(data.permissions)} */}
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Email Title'
                                name = 'title'
                                register = {register}
                                placeholder= 'Enter Email Title'
                                validation={{required: true}}
                                disabled={true}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            {/* {data.fields && data.fields.map(field => 
                                <h5 className="d-inline p-2"><span className="badge badge-primary badge-lg">{`$${field}`}</span></h5>
                            )} */}
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Email Action On'
                                name = 'action'
                                register = {register}
                                placeholder= 'Select Email Action'
                                options = {Object.keys(EMAIL_ACTIONS).map((key) => {
                                    return {
                                        name: key,
                                        value: key,
                                    }
                                })}
                                validation={{required: true}}
                                value={''}
                                setValue={setValue}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <label>
                                Fields to be used in subject or body
                            </label>
                            <br/>
                            {data.fields && data.fields.map(obj => `$${obj}`).join(' , ')}
                        </Col>
                        <Col md='12 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Email Subject'
                                name = 'subjectEn'
                                register = {register}
                                placeholder= 'Enter Email subject'
                                validation={{required: true}}
                            />
                        </Col>
                        <Col md='12 mb-3' >
                            <CKEditors
                                    activeclassName="p10"
                                    content={data.bodyEn}
                                    events={{
                                        'change': onBodyChange
                                    }}
                                />
                        </Col>
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default EditSystemEmail;
