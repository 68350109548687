import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login(email, password) {
    return api
      .post("/v1/crm/auth/login", {
        email,
        password
      })
      .then(response => {
        console.log(response);
        if (response.token) {
          TokenService.setUser(response.token);
        }
        return response;
      });
  }

  logout() {
    TokenService.removeUser();
  }
  register(username, email, password) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return TokenService.getUser();
  }
  getProfile() {
    return api.get("/v1/crm/auth/profile");
  }

}
const service = new AuthService();
export default service;

export const changePassword = (params) => {
  return api.post("/v1/crm/auth/change-password", params);
}
export const sendForgetPasswordOtp = (email) => {
  return api.post("/v1/crm/auth/send-change-password-otp", {email});
}
export const changePasswordByOtp = (params) => {
  return api.post("/v1/crm/auth/change-password-otp", params);
}

