import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { useForm } from 'react-hook-form';
import { FInput, FSelect, FTextArea } from 'CommonElements/Form';

import CommonModal from '../../../../src/Components/Common/Data/Ui-kits/Modals/common/modal';
import { transactionsService, BankAccount } from 'Services';
import { toast } from 'react-toastify';

import { ModalFooter } from 'reactstrap';
import { Close, SaveChanges } from '../../../Constant';

const InterFormModal = (
  { modal, NewMessage, toggle, setModal, refreshRecord },
  props
) => {
  const { data = {} } = props;
  const [isAccounInputDisable, setIsAccounInputDisable] = useState(false);
  const [accountToArr, setAccountToArr] = useState([]);
  const [accountFromArr, setAccountFromArr] = useState([]);
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    reset,
  } = useForm();
  useEffect(() => {
    if (!modal) {
      clearErrors();
      reset({
        login: '',
        accountTypeId: '',
        accountFrom: '',
        accountTo: '',
        note: '',
        amount: '',
      });
      setIsAccounInputDisable(false);
      setAccountToArr([]);
      setAccountFromArr([]);
    }
  }, [modal]);
  const handleLoginChange = (e) => {
    e.preventDefault();
    const loginNo = e.target.value;
    setValue('login', loginNo);
    // searchTimer =
    setTimeout(startSearch, 3000);
  };
  const startSearch = () => {
    const formFields = getValues();
    var loginNo = formFields.login;
    if (loginNo != null && loginNo !== '') {
      setIsAccounInputDisable(false);
      setValue('accountTypeId', '');
      BankAccount.getAccountSearch(`?login=${loginNo}&populateAll=true`)
        .then((res) => {
          if (res.id != null) {
            setValue('accountTypeId', res.id);
            setIsAccounInputDisable(true);
            setAccountFromArr([{ name: res.login, value: res.id }]);
            let accountToArray = [];
            res.otherAccounts.forEach((data) => {
              accountToArray.push({ name: data.login, value: data.id });
            });
            setAccountToArr(accountToArray);
          }
        })
        .catch((err) => {
          console.log('error');
        });
    }
  };
  const onSubmit = (data) => {
    if (data !== '') {
      setSubmitState({ loading: true });
      const payload = {
        accountFrom: Number(data.accountFrom),
        accountTo: Number(data.accountTo),
        note: data.note,
        amount: Number(data.amount),
      };
      transactionsService
        .postInterTransfer(payload)
        .then((res) => {
          setSubmitState({
            loading: false,
          });
          setModal(false);
          toast.success('Internal Transfer Successfully');
          refreshRecord();
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
          });

          toast.error('Internal Transfer  Failed ');
        });
      // alert('You submitted the form and stuff!');
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        toggler={toggle}
        isButtonShown={true}
      >
        <Form
          className='needs-validation'
          noValidate=''
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col md='6'>
              <FInput
                type='number'
                errors={errors}
                label='Login No:'
                name='login'
                register={register}
                placeholder='Enter Login No'
                value={data.login}
                validation={{ required: true }}
                handleOnChange={(e) => handleLoginChange(e)}
              />
            </Col>
            <Col md='6'>
              <FInput
                type='number'
                errors={errors}
                label='Account No:'
                name='accountTypeId'
                register={register}
                placeholder='Enter Account No'
                value={data.accountTypeId}
                validation={{ required: true }}
                disabled={isAccounInputDisable}
              />
            </Col>
            <Col md='6'>
              <FSelect
                control={control}
                errors={errors}
                label='Account From:'
                name='accountFrom'
                register={register}
                placeholder='Account From'
                options={accountFromArr}
                validation={{ required: true }}
                value={data.accountFrom}
                setValue={setValue}
              />
            </Col>
            <Col md='6'>
              <FSelect
                control={control}
                errors={errors}
                label='Account To:'
                name='accountTo'
                register={register}
                placeholder='Account To'
                options={accountToArr}
                validation={{ required: true }}
                value={data.accountTo}
                setValue={setValue}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FInput
                errors={errors}
                type='number'
                label='Amount:'
                name='amount'
                value={data.amount}
                register={register}
                placeholder='Enter your amount'
                validation={{ required: true }}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 10 }}>
            <Col md='12'>
              <FTextArea
                errors={errors}
                label='Note:'
                name='note'
                value={data.note}
                register={register}
                placeholder='Enter Note'
                validation={{ required: true }}
              />
            </Col>
          </Row>
          <ModalFooter>
            <Btn
              attrBtn={{
                color: 'secondary',
                onClick: () => setModal(false),
                type: 'button',
              }}
            >
              {Close}
            </Btn>
            <Btn
              disabled={submitState.loading}
              attrBtn={{ color: 'primary' }}
              // onSubmit={() => handleMyFunction()}
            >
              {SaveChanges}
            </Btn>
          </ModalFooter>
        </Form>
      </CommonModal>
    </Fragment>
  );
};
export default InterFormModal;
