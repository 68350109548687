import React, { Fragment, useState } from 'react';
import { Col, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { FSelect, FDateTime } from 'CommonElements/Form';

import { reportService } from 'Services';
import { toast } from 'react-toastify';
import { writeFile, utils } from 'xlsx';
import { FormGroup, Button } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
const SearchForm = ({ receivedReportData }, props) => {
  const { data = {} } = props;
  const [rec, setRec] = useState({});
  const [currentDate] = useState(new Date());
  const [threeMothOlderDate] = useState(new Date());
  const [, setSubmitState] = useState({
    loading: false,
  });
  const [option] = useState([
    { value: 'deposit', name: 'Depost' },
    { value: 'withdrawal', name: 'Withdrawal' },
    { value: 'funded', name: 'Funded Clients' },
    { value: 'unFunded', name: 'Un Funded Clients' },
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm();
  const onSubmit = (data) => {
    if (data !== '') {
      setSubmitState({ loading: true });
      // var fromDate = data.fromDate.toISOString();
      // const formattedFromDateString = fromDate.slice(0, 10).replace(/-/g, "-");
      // var toDate = data.toDate.toISOString();
      // const formattedToDateString = toDate.slice(0, 10).replace(/-/g, "-");
      const report = {
        // id: approvedData.id,
        dateFrom: data.fromDate,
        dateTo: data.toDate,
      };
      reportService
        .getReports(report, data.report)
        .then((res) => {
          setSubmitState({
            loading: false,
          });
          if (res.data != null) {
            receivedReportData(res.data);
            setRec(res);
          }
        })
        .catch((err) => {
          setSubmitState({
            loading: false,
          });
          toast.error('Deposit Failed ');
        });
    } else {
      errors.showMessages();
    }
  };
  const handleExport = () => {
    const workbook = exportToExcel(rec.data);
    writeFile(workbook, 'report_list.xlsx');
  };
  function exportToExcel(data) {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'report_sheet');
    return workbook;
  }
  return (
    <Fragment>
      <Form
        className='needs-validation'
        noValidate=''
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup className='form-group row' style={{ padding: '30px' }}>
          <Col md='2'>
            <FSelect
              control={control}
              errors={errors}
              label='Select Report'
              name='report'
              register={register}
              placeholder='Select Report'
              options={option}
              validation={{ required: true }}
              value={data.report}
              setValue={setValue}
            />
          </Col>
          <Col md='3'>
            <FDateTime
              errors={errors}
              label='From Date'
              name='fromDate'
              value={data.fromDate}
              register={register}
              placeholder='From Date'
              validation={{ required: true }}
              setValue={setValue}
              selectedValue={threeMothOlderDate.setDate(
                currentDate.getMonth() - 3
              )}
            />
          </Col>
          <Col md='3'>
            <FDateTime
              errors={errors}
              label='To Date'
              name='toDate'
              value={data.toDate}
              register={register}
              placeholder='To Date'
              validation={{ required: true }}
              setValue={setValue}
              selectedValue={currentDate}
            />
          </Col>
          <Col md='2' className=''>
            <Button
              style={{
                marginTop: 30,
                position: 'relative',
                left: '2rem',
                paddingLeft: '50px',
                paddingRight: '50px',
                fontSize: '15px',
              }}
            >
              Search
            </Button>
          </Col>
          {rec.data && rec.data.length > 0 ? (
            <Col md={'2'}>
              {/* <Btn attrBtn={{ color: 'success', onClick: handleExport }}>
                Export
              </Btn> */}
              <Button
                className='btn-primary'
                onClick={handleExport}
                style={{
                  marginTop: 30,
                  position: 'relative',
                  left: '2rem',
                  paddingLeft: '50px',
                  paddingRight: '50px',
                  fontSize: '15px',
                  backgroundColor: '#51bb25',
                  borderColor: '#51bb25',
                }}
              >
                Export
              </Button>
            </Col>
          ) : null}
        </FormGroup>
      </Form>
    </Fragment>
  );
};
export default SearchForm;
