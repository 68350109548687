import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { transactionsService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FSelect, FInput } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const AddBankAccount = ({ customerId,  loadData, accounts = []  }) => {
  const [modal, setModal] = useState(false);
  const [transactionType, setTransactionType] = useState('');

  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal)

  };
  const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, control } = useForm({
    // mode: 'onChange'
  });
  
  const formValueChange = (name, value) => {
    setValue(name , value);
    if(name === 'transactionType') {
        setTransactionType(value);
    }
  }

  const modalSubmit = formValues => {
    if (formValues !== '') {
        const {transactionType, ...params} = formValues;
        if(transactionType === 'internal-transfer') {
            delete params.accountId;
            // delete params.fee;
            delete params.gateway;
        } else {
            delete params.accountFrom;
            delete params.accountTo;
        }
        setLoading(true);
        transactionsService.postTransaction(transactionType, params).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Transaction Added, successfully');
            loadData();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to add Transaction');
        })
    } else {
        errors.showMessages();
        toast.error('Failed to add Transaction');

    }
};
  return (
    <Fragment>
        {/* <Button disabled={loading} onClick={toggle}  color={'primary'} >{'Add Bank Account'}</Button> */}
        <Btn attrBtn={{ disabled:loading, onClick:toggle, color: 'primary' }} >{'Add Transaction'}</Btn>

        <CommonModal
            isOpen={modal}
            title={'Add New Transaction'}
            toggler={toggle}
            formSubmit={()=>{}}
            hideFooter={true}
        >
            <Form  className="needs-validation" noValidate="" 
                onSubmit={handleSubmit(modalSubmit)}
            >
                    <Row>  
                        <Col md='12 mb-3' >
                            <FSelect
                                control={control}
                                errors={errors}
                                label = 'Transaction Type'
                                name = 'transactionType'
                                placeholder = 'Select Transaction Type'
                                register = {register}
                                options = {[
                                    {name: 'Deposit', value: 'deposit'},
                                    {name: 'Withdrawal', value: 'withdrawal'},
                                    {name: 'Internal Transfer', value: 'internal-transfer'},
                                ]}
                                validation={{required: true}}
                                value={transactionType}
                                setValue={formValueChange}
                            />
                        </Col>
                        {
                            getValues().transactionType !== 'internal-transfer' && <Col md='12 mb-3' >
                                <FSelect
                                    control={control}
                                    errors={errors}
                                    label = 'Select Account'
                                    name = 'accountId'
                                    register = {register}
                                    placeholder = 'Select Account'
                                    options = {accounts.filter(obj => obj.type === 'LIVE').map(obj => {
                                        return {value: obj.id, name: obj.login}
                                    })}
                                    validation={{required: true}}
                                    // value={accountType}
                                    setValue={formValueChange}
                                />
                            </Col>
                        }
                        {
                            getValues().transactionType === 'internal-transfer' && <Col md='6 mb-3' >
                                <FSelect
                                    control={control}
                                    errors={errors}
                                    label = 'Select Account From'
                                    name = 'accountFrom'
                                    register = {register}
                                    placeholder = 'Select Account From'
                                    options = {accounts.filter(obj => obj.type === 'LIVE').map(obj => {
                                        return {value: obj.id, name: obj.login}
                                    })}
                                    validation={{required: true}}
                                    setValue={formValueChange}
                                />
                            </Col>
                        }
                        {
                            getValues().transactionType === 'internal-transfer' && <Col md='6 mb-3' >
                                <FSelect
                                    control={control}
                                    errors={errors}
                                    label = 'Select Account To'
                                    name = 'accountTo'
                                    register = {register}
                                    placeholder = 'Select Account To'
                                    options = {accounts.filter(obj => obj.type === 'LIVE').map(obj => {
                                        return {value: obj.id, name: obj.login}
                                    })}
                                    validation={{
                                        required: true,
                                        validate: () => Number(getValues("accountFrom")) !== Number(getValues("accountTo"))
                                    }}
                                    setValue={formValueChange}
                                />
                            </Col>
                        }
                        
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Amount'
                                name = 'amount'
                                register = {register}
                                placeholder= 'Enter Amount'
                                validation={{required: true}}
                                type='number'
                            />
                        </Col>
                        {getValues().transactionType !== 'internal-transfer' && 
                            <Col md='6 mb-3' >
                                <FInput
                                    errors={errors}
                                    label = 'Fee'
                                    name = 'fee'
                                    register = {register}
                                    placeholder= 'Enter Fee'
                                    validation={{
                                        required: true,
                                        validate: () => Number(getValues("fee")) < Number(getValues("amount")),
                                    }}
                                    type='number'
                                />
                            </Col>
                        } 
                        {getValues().transactionType !== 'internal-transfer' &&
                            <Col md='6 mb-3' >
                                <FInput
                                    errors={errors}
                                    label = 'Gateway'
                                    name = 'gateway'
                                    register = {register}
                                    placeholder= 'Enter Gateway'
                                    validation={{required: true}}
                                />
                            </Col>
                            
                        }
                         
                        <Col md='6 mb-3' >
                            <FInput
                                errors={errors}
                                label = 'Note'
                                name = 'note'
                                register = {register}
                                placeholder= 'Enter Note'
                                validation={{required: true}}
                            />
                        </Col>
                        {/* <Col md='6 mb-3' >
                            <FSelect
                                errors={errors}
                                label = 'Select Leverage'
                                name = 'leverage'
                                register = {register}
                                placeholder = 'Select account Leverage'
                                options = {[{
                                    name: '1:400', value: 400,
                                },{
                                    name: '1:300', value: 300,
                                }]}
                                validation={{required: true}}
                                // value={400}
                                setValue={formValueChange}
                            />
                        </Col> */}
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading}  attrBtn={{ color: 'primary' }} >{'Add'}</Btn>
                        </Col>
                        
                    </Row>

                    {/* <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Update'}</Btn> */}
                </Form>
        </CommonModal>
    </Fragment>
  );
};

export default AddBankAccount;
