import api from './api';
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
};
export const getIbs = (params) => {
  return api.get('/v1/crm/customers/ibs?' + queryString(params));
};
export const updateIb = (id, params) => {
  return api.patch('/v1/crm/customers/ib/' + id, params);
};
export const deleteBulkIbs = (data) => {
  return api.delete('/v1/crm/customers/ibs', { data }, {});
};
export const postIbs = (params, token) => {
  return api.post('/v1/crm/customers/ib', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
