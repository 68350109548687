import React, { Fragment, useEffect, useState } from "react";
import { Col, Card, CardHeader, Table } from "reactstrap";
import { Users } from "../../../Constant";
import { H5 } from "../../../AbstractElements";
import { activitiesService } from "Services";
import TableLoader from "CommonElements/Table/TableLoader";
import TableNodata from "CommonElements/Table/TableNodata";
import { Row } from "react-bootstrap";
import LogViewModal from 'Components/Pages/ClientDetail/Logs/LogViewModal';

import TablePagination from "CommonElements/Table/TablePagination";
// import { useSelector } from "react-redux";

const HoverableRowsClass = () => {

  // const permissions = useSelector(
  //   (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  // );
  const [rec, setRec] = useState({});
  useEffect(() => {
    loadData(1);
  }, []);
  const loadData = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    activitiesService
      .getActivities({
        page,
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  const camelCaseToTitleCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, firstChar => firstChar.toUpperCase());
  }
  const [logData, setLogData] = useState({});
  const [show, setShow] = useState(false);
  const detailClick = (details = {}, content = {}) => {
    setLogData({details, content});
    setShow(true)
  }

  return (
    <Fragment>
      <Col sm="12">
        <Card>
          <CardHeader>
            <Row>
              <Col md="8">
                {" "}
                <H5>{Users}</H5>
              </Col>
              <Col md="4">
                <Row className="float-end">
                  <Col>
                 
                     
                  </Col>

                </Row>
              </Col>
            </Row>
          </CardHeader>
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">{"#"}</th>
                  <th scope="col">{"Type"}</th>
                  <th scope="col">{"Details"}</th>
                  <th scope="col">{"User"}</th>
                  <th scope="col">{"Client"}</th>
                </tr>
              </thead>
              {rec.loading && <TableLoader />}
              {!rec.loading && rec.data && rec.data.length === 0 && (
                <TableNodata title="Users" />
              )}
              <tbody>
                {rec.data &&
                  rec.data.map((item, id) => (
                    <tr key={id}>
                      <th scope="row">{item.id}</th>
                      <td >{camelCaseToTitleCase(item.type)}</td>
                      <td>
                        <span onClick={()=>{detailClick(item.details, item.content)}} className="m-2 font-info" style={{cursor: 'pointer'}}>
                          <i className="fa fa-eye"></i>
                        </span>
                      </td>
                      <td>{item.User?.firstName} {' '} {item.User?.lastName}</td>
                      <td>{item.Customer?.firstName} {' '} {item.Customer?.lastName}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TablePagination data={rec} loadData={loadData} />
            <LogViewModal show={show} toggle={()=>{setShow(!show)}} data={logData} />

          </div>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HoverableRowsClass;
