import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Table, Card, CardHeader, CardFooter } from 'reactstrap';
import { walletsService } from 'Services';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import EditWalletAccount from './EditWalletAccount';
import DeletWalletAccount from './DeletWalletAccount';
import AddWalletAccount from './AddWalletAccount';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';


const WalletAccounts = (props) => {
  const {
    customerId = '',
    activeTab, tabNumber
  } = props;
  const [rec, setRec] = useState({});
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );
  useEffect(()=>{
    if(activeTab === tabNumber) {
        loadData();
    }
  }, [activeTab, tabNumber]);

  const loadData = () => {
    walletsService.getWallets(customerId).then((res) => {
        setRec({
            ...res,
            loading: false,
        })
        }).catch(err =>{
            console.log('err ', err);
        });
  }

  return (
    <Fragment>
    <Card>
        <CardHeader className='p-3'>
        <Row>
            <Col md={6} className="d-flex">
                Wallets
            </Col>
            <Col md={6} className="d-flex flex-row-reverse">
                {getMenuPerm(permissions, 'wallets:create') === 1 &&
                    <AddWalletAccount customerId={customerId} loadData={loadData} />
                }
            </Col>
        </Row>
        </CardHeader>
    
     <div className="table-responsive">
        <Table hover>
            <thead>
                <tr>
                    <th scope="col">{'#'}</th>
                    <th scope="col">{'Network'}</th>
                    <th scope="col">{'Note'}</th>
                    <th scope="col">{'Address'}</th>
                    
                    <th scope="col">{'Actions'}</th>
                    
                </tr>
            </thead>
            {rec.loading && <TableLoader />}
            {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="Wallet Accounts" />}
            
            <tbody>
                {
                    rec.data && rec.data.map((item, id) =>
                        <tr key={id}>
                            <th scope="row">{item.id}</th>
                            <td >{item.network}</td>
                            <td >{item.note}</td>
                            <td >{item.address}</td>
                            
                            <td>
                                {getMenuPerm(permissions, 'wallets:update') === 1 &&
                                    <EditWalletAccount data={item} loadData={loadData} />
                                }
                                {getMenuPerm(permissions, 'wallets:delete') === 1 &&
                                    <DeletWalletAccount data={item} loadData={loadData}  />
                                }
                            </td>

                        </tr>
                    )
                }
            </tbody>
        </Table>
    </div>
    </Card>
</Fragment>
  );
};
export default WalletAccounts;