import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Loader = () =>(

  <div style={{width: '100%'}}>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
        </div>
);

export default Loader;