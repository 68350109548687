import React, { Fragment, useState } from "react";
import ReportsList from "./ReportsList";
import { Breadcrumbs } from "../../../AbstractElements";
import SearchForm from "./SearchForm";
import { Col, Card, CardHeader, Container, Row } from "reactstrap";
import { Reports } from "../../../Constant";
import { H5 } from "../../../AbstractElements";

const BasicTables = () => {
  const [reportData, setReportData] = useState([]);
  const handleChildData = (data) => {
    setReportData(data);
  };
  return (
    <Fragment>
      <Breadcrumbs title="Reports" mainTitle="" />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardHeader className="p-4">
              <Row>
                <Col md="8">
                  <H5>{Reports}</H5>
                </Col>
              </Row>
            </CardHeader>
            <SearchForm receivedReportData={handleChildData} />
            <ReportsList data={reportData} />
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default BasicTables;
