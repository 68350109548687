import React, { Fragment } from "react";
import { Col, Table } from "reactstrap";

const HoverableRowsClass = (props) => {
  const { data } = props;
  return (
    <Fragment>
      {data != null && data.length > 0 ? (
        <Col sm="12">
          <div className="table-responsive">
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">{"#"}</th>
                  <th scope="col">{"Login"}</th>
                  <th scope="col">{"Accoun Type"}</th>
                  <th scope="col">{"Amount"}</th>
                  <th scope="col">{"Fee"}</th>
                  <th scope="col">{"Gateway"}</th>
                  <th scope="col">{"Paid"}</th>
                  <th scope="col">{"Note"}</th>
                  <th scope="col">{"Created At"}</th>
                  <th scope="col">{"Updated At"}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, id) => (
                  <tr key={id}>
                    <th scope="row">{item.id}</th>
                    <td>{item["Account.login"]}</td>
                    <td>{item["Account.type"]}</td>
                    <td>{item.amount}</td>
                    <td>{item.fee}</td>
                    <td>{item.gateway}</td>
                    <td>{item.paid}</td>
                    <td>{item.note}</td>
                    <td>{item.createdAt}</td>
                    <td>{item.updatedAt}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      ) : (
        <div className="template">No Data Found</div>
      )}
    </Fragment>
  );
};

export default HoverableRowsClass;
