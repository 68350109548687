import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import SweetAlert from 'sweetalert2';

import { documentsService } from 'Services';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, CardHeader } from 'reactstrap';
import { Btn, UL, LI } from '../../../../AbstractElements';

const Documents = (props) => {
  const {
    customerId = '',
    loadData,
  } = props;
  const [submitState, setSubmitState] = useState({
    loading: false,
  });
  const { handleSubmit, setValue } = useForm();
  const onSubmit = data => {
    if (data !== '') {
        setSubmitState({loading: true});

        var formData = new FormData();
        formData.append("customerId", customerId);

        Object.keys(data).forEach(fieldKey => {
            for(let i =0; i < data[fieldKey].length; i++) {
                const fileData = data[fieldKey][i];
                formData.append(fieldKey, fileData);
            }
        });
        console.log('Upload Doc is ',formData);
        
        documentsService.uploadDocuments(formData).then(res => {
            setSubmitState({
                loading: false,
                status: 'Documents uploaded successfully'
            })
            // reset({
            //     PROOF_OF_ID: null,
            //     PROOF_OF_ADDRESS: null,
            //     ADDITIONAL: null,
            // });
            SweetAlert.fire({ title: 'Success', text: 'Documents uploaded sucessfully!', icon: 'success' });
            loadData();
        }).catch(err => {
            // reset({
            //     PROOF_OF_ID: null,
            //     PROOF_OF_ADDRESS: null,
            //     ADDITIONAL: null,
            // });
            setSubmitState({
                loading: false,
                status: 'Error in uploading documents'
            })

            SweetAlert.fire({ title: 'Failed', text: 'Error in uploading documents!', icon: 'error' });

        });
    //    console.log()
    }
};
const handleChange = (e) => {
    setValue(e.target.name, e.target.files);
}
  return (
    <Fragment>
        <Row>
            <Col md={8}>
                <Card>
                    <CardHeader className='p-3'>
                        Upload Documents
                    </CardHeader>
                    <Form  className="form theme-form needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{'Proof of Id'}</Label>
                                        <Col sm="9">
                                            <input onChange={handleChange} name="PROOF_OF_ID" className="form-control" type="file" multiple/>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{'Proof of Address'}</Label>
                                        <Col sm="9">
                                            <input onChange={handleChange} name="PROOF_OF_ADDRESS" className="form-control" type="file" multiple />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md={12}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{'Additional Documents'}</Label>
                                        <Col sm="9">
                                            <input onChange={handleChange} name="ADDITIONAL" className="form-control" type="file" multiple />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col md={12} className="d-flex flex-row-reverse">
                                <Btn disabled={submitState.loading}  attrBtn={{ color: 'primary' }} >{'Upload'}</Btn>
                            </Col>

                        </CardBody>
                    </Form>
                </Card>
            </Col>
            <Col md={4}>
                <Card className='p-3'>
                    <UL>
                        <LI>
                        Proof of ID max 2 files on every upload
                            - Front & Back for ID
                            - First & Second Page for Passport
                        </LI>
                        <LI>
                        Proof of Address max 2 files

                        </LI>
                        <LI>
                        Additional max 2 files on every upload, you can upload as many files as you want

                        </LI>
                    </UL>
                </Card>
            </Col>
        </Row>
      
    </Fragment>
  );
};
export default Documents;