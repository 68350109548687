import api from './api';

export const getDocuments = (customerId)=> {
    return api.get('/v1/crm/documents?customerId='+customerId)
}
export const uploadDocuments = (params)=> {
    return api.post('/v1/crm/documents', params)
}
export const deleteDocument = (id)=> {
    return api.delete('/v1/crm/documents/'+id)
}
export const updateDocument = (id, params)=> {
    return api.patch('/v1/crm/documents/'+id, params)
}
export const getDocumentSas = (id, number = 1)=> {
    return api.get('/v1/crm/documents/'+id, '?fileNumber='+number)
}